<template>
    <div class="max-w-[1364px] mx-auto mt-[29px]">
        <div class="flex flex-row items-center">
            <div>
                <a class="text-[15px] font-medium text-[#989898] td
                            hover:text-[#989898]" href="javascript:void(0);">
                    {{ $t('system.bosh_sahifa') }}
                </a>
            </div>
            <div class="flex items-center mx-[13px]">
                <img alt="" src="@/assets/chevron-right-gray.svg">
            </div>
            <div>
                <div class="text-[15px] text-[#000] font-medium td">
                    {{ $t('system.tolov') }}
                </div>
            </div>
        </div>
        <!--        <div class="my-[45px] text-[#000] font-medium text-[25px]">-->
        <!--            {{ 'To`lovni amalga oshirish' }}-->
        <!--        </div>-->

        <form>
            <!--            <HeaderCheck class="header-underline-date">-->
            <!--                <template #name>-->
            <!--                    {{ $t('system.your_inform') }}-->
            <!--                </template>-->
            <!--            </HeaderCheck>-->

            <div class="uk-flex mt-[41.4px]">
                <!--                <div>-->
                <!--                    <div class="text-[#000] text-[15px] font-medium">-->
                <!--                        {{ $t('system.phone') }}-->
                <!--                    </div>-->
                <!--                    <div class="w-[402px] p-[18px] h-[56px] flex flex-row-->
                <!--                     border border-[#C3B2FF] rounded-[8px] mt-[17px] relative"-->
                <!--                         :class="{'border-red-600' : form.errors.phone, 'border border-[#C3B2FF]' : !form.errors.phone}"-->
                <!--                    >-->
                <!--                        <div class="text-[#000] text-[16px] font-medium">-->
                <!--                            (+998)-->
                <!--                        </div>-->
                <!--                        <div class="ml-[3px]">-->
                <!--                            <input ref="phone"-->
                <!--                                   v-model="form.tempPhone"-->
                <!--                                   v-mask="'(00)000-00-00'"-->
                <!--                                   placeholder="&#45;&#45; -&#45;&#45; &#45;&#45; &#45;&#45;"-->
                <!--                                   type="text"-->
                <!--                                   class="focus:outline-0 w-full text-[#000] font-medium mr-[180px]"-->
                <!--                            >-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="ml-[35px]">-->
                <!--                    <div class="text-[#000] text-[15px] font-medium">-->
                <!--                        {{ $t('system.ism') }}-->
                <!--                    </div>-->
                <!--                    <div class="w-[402px] p-[18px] h-[56px] flex flex-row-->
                <!--                     border  rounded-[8px] mt-[17px] relative"-->
                <!--                         :class="{'border-red-600' : form.errors.first_name, 'border border-[#C3B2FF]' : !form.errors.first_name}"-->
                <!--                    >-->
                <!--                        <div class="ml-[3px]">-->
                <!--                            <input ref="first_name"-->
                <!--                                   v-model="form.first_name"-->
                <!--                                   type="text"-->
                <!--                                   class=" focus:outline-0 w-full text-[#000] font-medium mr-[180px]"-->
                <!--                            >-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="ml-[35px]">-->
                <!--                    <div class="text-[#000] text-[15px] font-medium">-->
                <!--                        {{ $t('system.surname') }}-->
                <!--                    </div>-->
                <!--                    <div class="w-[402px] p-[18px] h-[56px] flex flex-row-->
                <!--                     border border-[#C3B2FF] rounded-[8px] mt-[17px] relative"-->
                <!--                         :class="{'border-red-600' : form.errors.last_name, 'border border-[#C3B2FF]' : !form.errors.last_name}"-->
                <!--                    >-->
                <!--                        <div class="ml-[3px]">-->
                <!--                            <input ref="last_name"-->
                <!--                                   v-model="form.last_name"-->
                <!--                                   type="text"-->
                <!--                                   class=" focus:outline-0 w-full text-[#000] font-medium mr-[180px]"-->
                <!--                            >-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>

            <!--            <HeaderCheck class="header-underline-location mt-[60.4px] mb-[41.4px]">-->
            <!--                <template #name>-->
            <!--                    {{ $t('system.location_a') }}-->
            <!--                </template>-->
            <!--            </HeaderCheck>-->
            <!--            <div class="uk-flex mb-[41.2px]">-->
            <!--                <div>-->
            <!--                    <div class="text-[#000] text-[15px] font-medium mb-[17px]">-->
            <!--                        {{ $t('system.viloyat') }}-->
            <!--                    </div>-->
            <!--                    <div>-->
            <!--                        <div-->
            <!--                                :class="[openRegion ? 'relative uk-inline opened' : 'relative uk-inline', form.errors.region ? 'error' : '']">-->
            <!--                            <Select2 :options="regions"-->
            <!--                                     @focusin="openRegion=true"-->
            <!--                                     @focusout="openRegion=false"-->
            <!--                                     @select="regionSelectEvent($event)"-->
            <!--                            >-->
            <!--                            </Select2>-->
            <!--                            <img src="@/assets/chevron-down.svg" alt="" class="absolute top-[24px] right-[24px]">-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="ml-[35px]">-->
            <!--                    <div class="text-[#000] text-[15px] font-medium mb-[17px]">-->
            <!--                        {{ $t('system.tuman') }}-->
            <!--                    </div>-->
            <!--                    <div>-->
            <!--                        <div-->
            <!--                                :class="[openCity ? 'relative uk-inline opened' : 'relative uk-inline', form.errors.city ? 'error' : '']">-->
            <!--                            <Select2 :options="areas"-->
            <!--                                     @select="areaSelectEvent($event)"-->
            <!--                                     @focusin="openCity=true"-->
            <!--                                     @focusout="openCity=false"-->
            <!--                                     v-model="form.receiverCityId"-->
            <!--                            >-->
            <!--                            </Select2>-->
            <!--                            <img src="@/assets/chevron-down.svg" alt="" class="absolute top-[24px] right-[24px]">-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="uk-flex mb-[61px]">-->
            <!--                <div>-->
            <!--                    <div class="text-[#000] text-[15px] font-medium">-->
            <!--                        {{ $t('system.manzil') }}-->
            <!--                    </div>-->
            <!--                    <input type="text"-->
            <!--                           class="px-[18px] input py-[18px] bg-[#fff] w-[839px] h-[56px]-->
            <!--                          focus:outline-0 border border-[#C3B2FF] rounded-[8px]-->
            <!--                          mt-[17px] text-[#000] "-->
            <!--                           placeholder="Misol: Chilonzor tumani 20kv"-->
            <!--                           v-model="form.address"-->
            <!--                           ref="address"-->
            <!--                           :class="{'border-red-600' : form.errors.address, 'border border-[#C3B2FF]' : !form.errors.address}"-->
            <!--                    >-->
            <!--                </div>-->
            <!--                <button type="submit" @click="submit"-->
            <!--                        class="bg-[#6848D2] rounded-[7px] text-[16px] text-[#fff] ml-[35px] mt-[39px]-->
            <!--                        cursor-pointer px-[10px] py-[18px] max-h-[56px] flex items-center"-->
            <!--                >-->
            <!--                    подтвердить-->
            <!--                </button>-->
            <!--            </div>-->

            <PaymentType />

            <div class="mb-[60.8px] ">
                <HeaderCheck class="header-underline-order mb-[48.7px]">
                    <template #name>
                        {{ $t('system.your_order') }}
                    </template>
                </HeaderCheck>
                <div class="uk-grid order-bottom-line">
                    <div class="uk-navbar-left">
                        <div class="text-[#000000] text-[18px] font-semibold leading-[22px]">
                            {{ $t('system.mahsulot') }}
                        </div>
                    </div>
                    <div class="uk-navbar-right">
                        <ul class="flex space-x-36 text-[#000000] text-[18px] font-semibold leading-[22px] mr-[75px]">
                            <li>
                                {{ $t('system.count') }}
                            </li>
                            <li>
                                {{ $t('system.price') }}
                            </li>
                            <!-- <li>
                                Доставка
                            </li> -->
                            <li>
                                {{ $t('system.all_of_them') }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-for="(product, index) in items" :key="index" class="flex mt-[36px] order-bottom-line-a">
                    <div class="bg-[#F7F7FF] rounded-[8px] py-[13px] px-[25px] mr-[26px]">
                        <img v-if="product.images.length > 0" :src="product.images[0].image_url" class="w-[55px] h-[74px]"
                            :alt="product.name" :title="product.name">
                        <img v-else class="w-[55px] h-[74px]" src="@/assets/atlas.png" alt="">
                    </div>
                    <div
                        class="text-[#000000] text-[16px] font-medium leading-[19px] self-center w-[319px] mr-[301px] order-bottom-line-b">
                        {{ product.name }}
                    </div>
                    <div class="self-center mr-[111px] order-bottom-line-c text-[14px] font-semibold">
                        <count-btn-v3 :product="product" :key="product.id" />
                        <!--                {{product.count + ' шт'}}-->
                    </div>
                    <div class="text-[#000000] text-[20px] font-medium self-center mr-[99px] order-bottom-line-d">
                        {{ number_format(product.price, ' ', ' ') }} {{ $t('system.som') }}
                    </div>
                    <div class="text-[#6C4BD8] text-[20px] font-semibold self-center">
                        {{ number_format(product.count * product.price, ' ', ' ') }} {{ $t('system.som') }}
                    </div>
                </div>
                <div class="justify-content-right">
                    <div class="flex justify-end mt-[40px] self-center order-right-line">
                        <div class="text-[17px] font-bold text-[#000000] mr-[120px] order-right-little-line">
                            {{ $t('system.all_of_them') }}:
                        </div>
                        <div class="text-[#6C4BD8] text-[20px] font-semibold mr-[15px] w-[115px]">
                            {{ number_format(all_product_sum, ' ', ' ') }} {{ $t('system.som') }}
                        </div>
                    </div>
                    <div v-if="is_with_shipping == 1" class="flex justify-end mt-[40px] self-center order-right-line">
                        <div class="text-[17px] font-bold text-[#000000] mr-[120px] order-right-little-line-a">
                            {{ 'BTS Pochta (Вес: ' + all_weight + ' kg)' }}
                        </div>
                        <div class="text-[#6C4BD8] text-[20px] font-semibold mr-[15px] w-[115px]">
                            {{ number_format(shipping_sum, ' ', ' ') + ' ' + $t('system.som') }}
                        </div>
                    </div>
                    <div v-if="is_with_shipping == 1" class="flex justify-end mt-[40px] self-center order-right-line">
                        <div class="text-[17px] font-bold text-[#000000] mr-[120px] order-right-little-line-b">
                            {{ $t('system.all_of_them') + '(Xizmatlar bilan)' }}:
                        </div>
                        <div class="text-[#6C4BD8] text-[20px] font-semibold mr-[15px] w-[115px]">
                            {{ number_format(all_sum, ' ', ' ') }} {{ $t('system.som') }}
                        </div>
                    </div>
                </div>

                <div class="uk-flex uk-flex-right" style="margin-top: 50px; margin-left: auto; max-width: 500px;">
                    <button @click="click" type="button"
                        class="uk-navbar-right px-[20px] py-[12px] rounded-[7px] bg-[#6C4BD8] text-[#FFFFFF] text-[16px] font-medium leading-[19px] transition-style">
                        {{ $t('system.pay_through_click') }}
                    </button>
                    <button @click="payment" type="button"
                        class="uk-navbar-right px-[20px] py-[12px] rounded-[7px] bg-[#6C4BD8] text-[#FFFFFF] text-[16px] font-medium leading-[19px] transition-style">
                        {{ $t('system.pay_through_payme') }}
                    </button>
                </div>
            </div>


            <vue-basic-alert :duration="300" :closeIn="3000" ref="alert" />
        </form>
    </div>
</template>

<script>

import PaymentType from "@/components/layouts/PaymentType";
// import OrderDetail from "@/components/layouts/OrderDetail";
import HeaderCheck from "@/components/blocks/HeaderCheck";
import Select2 from 'vue3-select2-component';
// import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from "axios";
import { apiUrls } from "@/utils/const/apiUrls";
import CountBtnV3 from "@/components/blocks/CountBtnV3";
import VueBasicAlert from 'vue-basic-alert'
import mixin from "@/boot/boot-mixin";
// import Select from "@/components/layouts/Select";

export default {
    name: "OformitPokupku",
    components: { CountBtnV3, HeaderCheck, PaymentType, VueBasicAlert },
    mixins: [mixin],
    props: {
        agreement_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            likes: 1,
            items: [],
            order_id: '',
            is_with_shipping: 0,
            shipping_sum: 0,
            all_product_sum: 0,
            all_weight: 0,
            all_sum: 0,
            regions: [],
            areas: [],

            openCity: false,
            openRegion: false,
            date: null,
        }
    },
    methods: {

        updateLikes(data) {
            this.likes = data;
        },

        payment() {
            let Base64 = {
                _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
                encode: function (e) {
                    let t = "";
                    let n, r, i, s, o, u, a;
                    let f = 0;
                    e = Base64._utf8_encode(e);
                    while (f < e.length) {
                        n = e.charCodeAt(f++);
                        r = e.charCodeAt(f++);
                        i = e.charCodeAt(f++);
                        s = n >> 2;
                        o = (n & 3) << 4 | r >> 4;
                        u = (r & 15) << 2 | i >> 6;
                        a = i & 63;
                        if (isNaN(r)) {
                            u = a = 64
                        } else if (isNaN(i)) {
                            a = 64
                        }
                        t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
                    }
                    return t
                },
                _utf8_encode: function (e) {
                    e = e.replace(/\r\n/g, "\n");
                    let t = "";
                    for (let n = 0; n < e.length; n++) {
                        let r = e.charCodeAt(n);
                        if (r < 128) {
                            t += String.fromCharCode(r)
                        } else if (r > 127 && r < 2048) {
                            t += String.fromCharCode(r >> 6 | 192);
                            t += String.fromCharCode(r & 63 | 128)
                        } else {
                            t += String.fromCharCode(r >> 12 | 224);
                            t += String.fromCharCode(r >> 6 & 63 | 128);
                            t += String.fromCharCode(r & 63 | 128)
                        }
                    }
                    return t
                }
            }

            let url = 'https://checkout.paycom.uz/';
            let params = 'm=62d7f7a739c675be34e2c294;';
            params += 'ac.order_id=' + this.order_id + ';';
            params += 'a=' + this.all_sum + ';';
            params += 'l=' + 'uz;';
            params += 'c=https://uzkarta.uz/#/cabinet;';
            params += 'ct=15000'

            url += Base64.encode(params);

            // https://checkout.paycom.uz/base64(m=62d7f7a739c675be34e2c294;ac.order_id=000001;a=500000;l=uz;c=http://195.158.9.83:8081/#/cabinet;ct=15000)

            window.open(url, '_blank');
        },

        click() {
            let Base64 = {
                _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
                encode: function (e) {
                    let t = "";
                    let n, r, i, s, o, u, a;
                    let f = 0;
                    e = Base64._utf8_encode(e);
                    while (f < e.length) {
                        n = e.charCodeAt(f++);
                        r = e.charCodeAt(f++);
                        i = e.charCodeAt(f++);
                        s = n >> 2;
                        o = (n & 3) << 4 | r >> 4;
                        u = (r & 15) << 2 | i >> 6;
                        a = i & 63;
                        if (isNaN(r)) {
                            u = a = 64
                        } else if (isNaN(i)) {
                            a = 64
                        }
                        t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
                    }
                    return t
                },
                _utf8_encode: function (e) {
                    e = e.replace(/\r\n/g, "\n");
                    let t = "";
                    for (let n = 0; n < e.length; n++) {
                        let r = e.charCodeAt(n);
                        if (r < 128) {
                            t += String.fromCharCode(r)
                        } else if (r > 127 && r < 2048) {
                            t += String.fromCharCode(r >> 6 | 192);
                            t += String.fromCharCode(r & 63 | 128)
                        } else {
                            t += String.fromCharCode(r >> 12 | 224);
                            t += String.fromCharCode(r >> 6 & 63 | 128);
                            t += String.fromCharCode(r & 63 | 128)
                        }
                    }
                    return t
                }
            }

            let url =' https://my.click.uz/services/pay?';
            let params = `service_id=30687&merchant_id=23035&amount=${this.all_sum}&transaction_param=${this.order_id}&return_url=https://uzkarta.uz/#/cabinet&card_type=uzcard`

            url +=params;
            window.open(url, '_blank');
        },

        getProducts(val) {
            this.is_with_shipping = 0
            this.shipping_sum = 0
            this.all_sum = 0
            this.all_product_sum = 0
            axios.get(apiUrls.PRODUCT_AGREEMENTS + this.tableFilterQuery({ id: val }))
                .then(res => {
                    this.items = res.data.data[0].items;
                    this.order_id = res.data.data[0].agreement_number
                    this.is_with_shipping = res.data.data[0].is_with_shipping
                    this.shipping_sum = res.data.data[0].shipping_sum
                    this.all_weight = res.data.data[0].all_weight
                    this.all_product_sum = res.data.data[0].all_product_sum
                    this.all_sum = res.data.data[0].all_sum
                })
                .catch(function (error) {
                    console.log(error);
                    this.loader = false;
                });
        },

        tableFilterQuery(filter) {
            if (!filter) return '';
            let queryArray = [];
            Object.keys(filter).map((objectKey, index) => {
                const value = filter[objectKey];
                if (value === undefined || value === null || value.length === 0)
                    return;
                queryArray.push(objectKey + '=' + value);
            });

            if (queryArray.length !== 0)
                return '?' + queryArray.join('&');
            else return ""
        },

    },
    mounted() {
        if (this.agreement_id) {
            this.getProducts(this.agreement_id)
        }
    }
}
</script>

<style>
.select2-container {
    width: 402px !important;
}

.select2-selection {
    height: 56px !important;
    display: flex !important;
    align-items: center !important;
    padding: 18px !important;
    border: 1px solid #C3B2FF !important;
    border-radius: 8px !important;
}

.error .select2-selection {
    border: 1px solid #f00 !important
}

.opened .select2-selection {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.select2-selection__arrow {
    display: none !important;
}

.select2-dropdown {
    border: 1px solid #C3B2FF !important;
    border-top: 0px !important;
    padding: 12px 12px 12px 14.5px !important;
}

.select2-search__field {
    background-color: #FAF9FE !important;
    border: 1px solid #C3B2FF !important;
    border-radius: 8px !important;
    height: 42px !important;
}

.select2-search__field:focus {
    outline: none !important;
    color: #000 !important;
}

.select2-selection__rendered {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.select2-results__option {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 13px 0px 12px 15.5px !important;
    border-bottom: 1px solid #E7E2F9 !important;
    background-color: #fff !important;
}

.select2-results__option:hover {
    background-color: #FAF9FE !important;
    transition: all .3s !important;
}

.select2-results__options {
    padding-right: 4px;
}

.select2-results__options::-webkit-scrollbar {
    width: 5px;
}

.select2-results__options::-webkit-scrollbar-track {
    box-shadow: none;
    height: 32px !important;
}

.select2-results__options::-webkit-scrollbar-thumb {
    height: 32px !important;
    background-color: #6949D3;
    border-radius: 99999999px;
}

.dp__input {
    background-color: none !important;
    border-radius: 4px;
    font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /* border: none!important; */
    border: none;
    outline: none;
    transition: border-color .2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 130%;
    font-size: 1rem;
    /* line-height: 1.5rem; */
    padding: 4px 11px;
    color: var(--dp-text-color);
    box-sizing: border-box;
    margin-left: 17%;
    margin-top: -5%;
}

.db__icon {
    display: none !important;
}

.db__icon {
    display: none !important;
}

.dp__input_icon {
    display: none;
}

.dp__cancel {
    /* display: none; */
}

.dp__action_row {
    /* display: none; */
}

.dp__calendar_header {
    display: none;
}
</style>