export default {
    "system": {
        "actions": "Действия",
        "activesessiondate": "Дата активной сессии",
        "app_action_group_name": "Название группы действий приложения",
        "are_you_sure_logout": "Выйти?",
        "cancel": "Отмена",
        "created_date": "Дата создание",
        "created_user_name": "Созданное имя пользователя",
        "data_not_found": "Данные не найдены",
        "description": "Описание",
        "edit": "Редактировать",
        "email": "Электронная почта",
        "error_connection": "Ошибка подключения к серверу",
        "female": "Женщина",
        "fio": "ФИО",
        "gender": "Пол",
        "see_all": "Показать еще",
        "id": "ID",
        "is_module": "Модуль",
        "enter": "ВОЙТИ",
        "text": "Авторизуйтесь, чтобы продолжить работу в системе",
        "login": "Логин",
        "password": "Пароль",
        "remember": "Запомнить",
        "forgot_password": "Восстановить пароль",
        "reset": "Cбросить",
        "logout": "Выход",
        "male": "Мужчина",
        "name": "Название",
        "new": "Создать",
        "no": "Отмена",
        "organization_name": "Наименование организации",
        "os_info": "ОС",
        "phone": "Телефон",
        "remote_address": "Удаленный адрес",
        "role": "Роль пользователя",
        "save": "Сохранить",
        "short_name": "Короткое название",
        "status": "Статус",
        "to_back": "Назад",
        "token": "Токен",
        "user_name": "ФИО пользователья",
        "user_role_name": "Название роли пользователей",
        "value": "Значение",
        "yes": "Да",
        "delete": "Удалить",
        "iichdk": "IICHDK",
        "cartography": "KARTOGRAFIYA",
        "maps_and_atlas": "Xarita va Atlaslar",
        "social_media_text": "Мы в социальных сетях",
        "client_confirm": "Утвержден",
        "client_paid": "Оплаченный",
        "invoice_number": "Номер счета",
        "all_amount": "Общая цена",
        "all_count": "Общее количество",
        "sent_time": "Дата подачи",
        "products_name": "Название товара",
        "count": "Количество",
        "price": "Цена",
        "product_info": "Информация о товаре",
        "product_img": "Изображение продукта",
        "full_info": "Подробная информация",
        "are_you_sure_remove_this_row": "Вы действительно хотите удалить эту строку?",
        "required": "Обязательное поле",
        "are_you_sure_set_status_this_row": "Вы действительно хотите изменить статус этой строки?",
        "qollab_quvvatlash": "Сервис поддержки",
        "call_admin": "Связаться с администратором",
        "yetkazib_berish": "доставки",
        "savat": "Корзинка",
        "kabinet": "Кабинет",
        "category": "Категории",
        "xarid_qilishga_shoshiling": "Спешите купить",
        "milliy_atlas": "Национальный атлас Узбекистана",
        "atlas_yaratildi": "Создан национальный атлас Узбекистана",
        "batafsil": "Более",
        "yangi_mahsulot": "Новые продукты",
        "add_bucket": "Добавить в корзину",
        "deliver": "Доставка",
        "mahsulot": "Товары",
        "bozorga_borishni_hojati_yoq": "Теперь вам не нужно идти на рынок",
        "qulay_narxda_yetkazib": "У нас доступные цены и доставка на дом",
        "tez_yetkazish": "Быстрая доставка по всему Узбекистану",
        "qoyil_qolasiz": "Вы будете впечатлены нашими услугами",
        "location": "Ташкент, 100170, М.Улугбекский район, улица Зиолилар, 6",
        "inform": "Информация",
        "deliver_inform": "Информация о доставке",
        "back_product": "Возврат товара",
        "about": "О нас",
        "messenger": "Следите за нами в социальных сетях",
        "address_in_map": "Наше местоположение на карте",
        "back_footer": " Агентство кадастра при Министерстве экономики и финансов Республики Узбекистан. Все права защищены.",
        "tizim": "Система",
        "tizim_a": "разработано государственным учреждением.",
        "choose_payment": "Выберите форму оплаты",
        "through_pay": "Платить с помощью",
        "naqd_tola": "Наличными",
        "trans_shakli": "В виде денежного перевода",
        "your_order": "Ваш заказ",
        "result": "Итог",
        "all_of_them": "Итого :",
        "pay_through_payme": "Оплатить через Payme",
        "pay_through_click": "Оплатить через Click",
        "bosh_sahifa": "Домашняя страница",
        "buyurtma_berish": "Разместить заказ",
        "buyurtmani_shakllantirish": "Формирование заказа",
        "your_inform": "Ваша информация",
        "ism": "Имя",
        "surname": "Фамилия",
        "location_a": "Расположение",
        "viloyat": "Вилоят",
        "tuman": "Город / Район",
        "region": "Регион / область",
        "manzil": "Адрес",
        "placeholder_1": "Пример: Чиланзарский район 20 кв.м.",
        "som": "сўм",
        "personal_cabinet": "Доступ в личный кабинет",
        "parolni_unutdingizmi": "Забыли Пароль?",
        "hisobingiz_yoqmi": "Нет аккаунта?",
        "register": "Зарегистрироваться",
        "maydon_notogri": "Поле заполено неверно",
        "tasdiqlash": "Подтверждение",
        "yetkazib_beriladi": "Доставка по Республике Узбекистан",
        "sevimli": "Избранное",
        "toza": "Очистить",
        "yaqinda_korilgan_mahsulot": "Недавно просмотренные товары",
        "buyurtma_asosida_xarid_qiling": "Купить картографическую продукцию по распродаже на заказ.",
        "alohida_hisoblanadi": "Доставка по Узбекистану осуществляется отдельно.",
        "online_shop": "онлайн магазин",
        "eslab_qolish": "запомнить меня",
        "korib_chiqishingizni_maslahat": "Рекомендуем посмотреть",
        "oquv_atlaslari": "Образовательные атласы",
        "olkashunoslik": "Изданы краеведческие атласы на узбекском и русском языках.",
        "yozuvsiz_xaritalar": "Учебные атласы и бесплатные карты по географии",
        "uz_sum": "сўм",
        "all_category": "Все категории",
        "track_status": "Статус доставки",
        "copied_error": "Ошибка",
        "copied": "Успешно",
        "copied_error_message": "Произошла ошибка при копировании ссылки",
        "copied_message": "Текущая ссылка скопирована в буфер обмена",
        "ish_vaqti": "Рабочее время",
        "site_is_test_mode": "Сайт работает в тестовом режиме",
        "search":"Поиск",
        "dona":"шт.",
        "shaxsiy_kabinet":"Личный кабинет",
        "shaxsiy_malumotlar":"Персональные данные",
        "mening_buyurtmalarim":"Мои заказы",
        "buyurtma_raqami":"Номер заказа",
        "buyurtma_sanasi":"Дата заказа",
        "yuboruvchida":"У отправителя",
        "kuryer_qabul_qildi":"Курьер принял",
        "kuryer_yetkazib_beradi":"Курьер доставляет",
        "yetkazib_berildi":"Доставлен",
        "bekor_qilmoq":"Вы действительно хотите отменить этот заказ?",
        "tolov":"Оплата",
        "chegirma":"скидка",
        "history_purchase":"История покупок",
        "sevimlilar_royxati":"Cписок избранного пуст",
        "sevimliga_qoshish":"Добавляйте понравившиеся товары в избранное, чтобы посмотреть или купить их позже",
        "ulashing":"Поделись",
        "sotuvda":"В продаже",
        "mavjud":"Есть",
        "savatchaga":"В корзину",
        "previous":"Предыдущая",
        "next":"Следующая",
        "oneid": "через OneId",
        "sms": "через SMS-уведомление",
        "codeLogin": "Получить код активации",
        "code": "Код",
        "enter_to_system": "Вход в личный кабинет",
        "cabinetSection": {
            "leaveAcc": "Вы хотите выйти?"
        },
        "karzinka": {
            "totalPrice": "Итого"
        },
        "navbar": {
            "callsNotSupport": "Телефонные звонки не поддерживаются на этом устройстве."
        },
        "OrderKarzinka": {
            "shippingPrice" : "Стоимость доставки"
        }
    },
};
