<template class="relative">
  <div class="max-w-[1920px] mx-auto overflow-hidden relative">
    <header class="bg-[#6848D2] navbar">
      <div class="w-full border-b border-[#8060E8] xl:block hidden">
        <div class="max-w-[1364px] mx-auto ">
          <div class="flex items-center py-[17.5px]">
            <div class="flex flex-row items-center">
              <div class="">
                <a href="">
                  <div class=" hover:bg-[#fff] hover:text-[#6848d2] text-[#fff] border border-[#fff]
                                    rounded-full py-[11px] px-[10px] justify-center items-center"
                    style="transition: all .8s">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.426" height="12.022" viewBox="0 0 14.426 12.022">
                      <path
                        d="M5.66,9.923l-.239,3.356a.834.834,0,0,0,.667-.323l1.6-1.53,3.317,2.429c.608.339,1.037.16,1.2-.56l2.177-10.2h0c.193-.9-.325-1.251-.918-1.03L.67,6.963C-.2,7.3-.191,7.789.521,8.01L3.793,9.027l7.6-4.755c.358-.237.683-.106.415.131Z"
                        transform="translate(0 -2)" fill="currentColor" />
                    </svg>
                  </div>
                </a>
              </div>
              <div class="ml-[12px]">
                <a href="#">
                  <div class=" hover:bg-[#fff] hover:text-[#6848d2] text-[#fff] border border-[#fff]
                                    rounded-full py-[8px] px-[12px] justify-center items-center"
                    style="transition: all .8s">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.724" height="16.709" viewBox="0 0 8.724 16.709">
                      <path
                        d="M13.318,2.774h1.593V.118A21.465,21.465,0,0,0,12.591,0a3.6,3.6,0,0,0-3.87,3.926v2.34H6.187v2.97H8.721v7.473h3.107V9.236H14.26l.386-2.97H11.827V4.22c0-.858.242-1.446,1.491-1.446Z"
                        transform="translate(-6.187)" fill="currentColor" />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
            <div class="ml-auto flex flex-row">
              <div class="flex flex-row items-center ml-[12px] border-r-2 pr-[12px]">
                <div class="navbar-item flex items-center">
                  <img src="../../assets/time_icon.svg" class="pr-[12px]">
                  {{ $t('system.ish_vaqti') }}
                </div>
                <div class="ml-[22px] navbar-item font-bold">
                  9.00 - 18.00
                </div>
              </div>
              <div class="flex flex-row items-center ml-[12px] border-r-2 pr-[12px]">
                <div class="navbar-item ">
                  {{ $t('system.qollab_quvvatlash') }}
                </div>
                <a href="#" @click="makeCall"
                  class="ml-[22px] navbar-item font-bold cursor-pointer hover:text-white hover:no-underline">
                  71 262 - 53 - 66
                </a>
              </div>
              <div class="flex flex-row items-center ml-[12px] border-r-2 pr-[12px]">
                <div class="navbar-item ">
                  {{ $t('system.call_admin') }}
                </div>
                <a href="#" @click="makeCallAdmin"
                  class="ml-[22px] navbar-item font-bold cursor-pointer hover:text-white hover:no-underline">
                  97 343-15-12
                </a>
              </div>
              <div class="flex  items-center cursor-pointer ml-[12px]" uk-filter="target: .js-filter; animation: fade">
                <div class="flex flex-row items-center">
                  <div class="navbar-item js-filter flex flex-row">
                    <!-- {{language}} -->
                    <div class="tag-ru text-[16px] font-medium">
                      {{ this.$store.getters.getLang == 'ru' ? 'Ru' : 'Uz' }}
                    </div>
                    <div class="tag-uz text-[16px] font-medium">
                      {{ this.$store.getters.getLang == 'ru' ? 'Ru' : 'Uz' }}
                    </div>
                  </div>
                  <div class="ml-[12px] pt-[5px]">
                    <img src="@/assets/dropdown-icon.svg" alt="">
                  </div>
                </div>
                <div uk-dropdown="mode: click;pos: bottom-right;" id="lang-menu"
                  class="rounded-[5px] bg-[#fff] uk-box-shadow-medium"
                  style="padding: 10px!important; min-width: 47px!important;">
                  <div class=" text-[16px] font-medium text-[#6C4BD8] hover:text-[#000] border-b px-3 pb-3"
                    uk-filter-control=".tag-ru" @click="setLan('ru')">
                    Русский
                  </div>
                  <div class="uk-active text-[#6C4BD8] hover:text-[#000] text-[16px] font-medium px-3 pt-3"
                    uk-filter-control=".tag-uz" @click="setLan('uz')">
                    O'zbek
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-[#6848D2]" uk-sticky="sel-target: .navbar; cls-active: uk-navbar-sticky;
                    top: 200;animation: uk-animation-slide-top;show-on-down: true; show-on-up: false">
        <div class="max-w-[1364px] mx-auto xl:px-0 px-[40px] navbar">
          <div class="cursor-pointer hover:no-underline flex items-center py-[22.5px] justify-between">
            <div class="flex items-center">
              <router-link to="/" class="td bg-[#fff] rounded-full p-[5px]">
                <img src="@/assets/navbar-logo.svg" alt="logo">
              </router-link>
              <router-link to="/" class="td ml-[20px]">
                <div class="uppercase navbar-item">
                  Kartografiya
                </div>
                <div class="navbar-item uppercase font-bold">
                  IICHDK
                </div>
              </router-link>
            </div>
            <div class="flex items-center max-w-[1005px]">
              <div class="xl:block hidden absolute left-[33%]">
                <input @blur="searcherHover ? undefined : inputFocus = false" @focus="inputFocus = true" type="text"
                  :placeholder="$t('system.search')" v-model="searcher" ref="searcher" class="test_rp px-[23px] input py-[13px] bg-[#fff] w-[525px] h-[46px]
                        focus:outline-0 rounded-[5px] shadow-sm">
                <a @click="resetInput">
                  <img src="@/assets/search-icon.svg" alt="" class="absolute right-[20px] bottom-[14px]">
                </a>
              </div>
              <div class="ml-auto flex flex-row">
                <div class="xl:block hidden">
                  <div class="flex flex-row ml-[77px] ">
                    <div>
                      <a href="javascript:void(0);" class="flex flex-col td justify-center ">
                        <div class="mx-auto">
                          <img src="@/assets/dastavka-icon.svg" alt="dastavka">
                        </div>
                        <div class="navbar-item mt-[8px]">
                          {{ $t('system.yetkazib_berish') }}
                        </div>
                      </a>
                    </div>
                    <div class="vertical-divider-1"></div>
                    <div>
                      <a href="javascript:void(0);" class="flex flex-col td justify-center  relative"
                        @click.stop="reloadStoreItem" uk-toggle="target: #offcanvas-flip">
                        <div class="mx-auto">
                          <img src="@/assets/korzinka-icon.svg" alt="">
                        </div>
                        <div class="navbar-item mt-[7px]">
                          {{ $t('system.savat') }}
                        </div>
                        <div
                          class="absolute top-[-10px] right-[9px] text-[#fff] text-[15px] bg-[#14BF30] px-[7px]  rounded-[50%]"
                          v-if="this.$store.getters.getSIProducts.length > 0">
                          {{ this.$store.getters.getSIProducts.length }}
                        </div>
                      </a>
                    </div>
                    <div class="vertical-divider-2"></div>
                    <div>
                      <router-link :to="'/cabinet'" v-if="storeUser.id" class="flex flex-col td justify-center cabinet">
                        <div class="mx-auto">
                          <img src="@/assets/kabinet-icon.svg" alt="icon">
                        </div>
                        <div class="navbar-item mt-[4.4px]">
                          {{ $t('system.kabinet') }}
                        </div>
                      </router-link>
                      <button v-else type="button" class="flex flex-col td justify-center cabinet"
                        @click="openRegisterDialog()">
                        <div class="mx-auto">
                          <img src="@/assets/kabinet-icon.svg" alt="icon">
                        </div>
                        <div class="navbar-item mt-[4.4px]">
                          {{ $t('system.kabinet') }}
                        </div>
                      </button>
                      <!-- <a v-else :href="oneIDUri" class="flex flex-col td justify-center cabinet">
                        <div class="mx-auto">
                          <img src="@/assets/kabinet-icon.svg" alt="icon">
                        </div>
                        <div class="navbar-item mt-[4.4px]">
                          {{ $t('system.kabinet') }}
                        </div>
                      </a> -->
                    </div>
                  </div>
                </div>
                <div class="flex flex-col xl:hidden block cursor-pointer" @click="offcanvas = true">
                  <div class="burger-line mb-[5px]"></div>
                  <div class="burger-line mb-[5px]"></div>
                  <div class="burger-line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>

  <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true" class="uk-overlay-primary h-full">
    <div class="uk-offcanvas-bar" style="padding: 0 !important; width: 504px!important;height: 100%!important;">
      <Karzinka @checkOrderConfirm="checkOrder" :show="test" />
    </div>
  </div>

  <div>
    <div class="absolute left-[33%] z-[99] top-[18.4%]">
      <div v-if="inputFocus && searcher" @mouseover="searcherHover = true" @mouseleave="searcherHover = false"
        class="bg-white w-[525px] rounded-b-[5px] border-none pb-[5px] overflow">
        <div class="item fruit text-black pl-[20px] hover:cursor-pointer hover:bg-[#c2c6d1] space-y-16"
          v-for="fruit in products" :key="fruit">
          <router-link :to="'/singleProduct/' + String(fruit.id)" class="hover:no-underline text-black hover:text-black"
            @click="resetInput">
            <div class="flex space-x-16 self-center h-[70px]">
              <div class="w-[7%] self-center">
                <img class="" v-if="fruit.images.length > 0" :src="fruit.images[0].image_url" :alt="fruit.name"
                  :title="fruit.name">
              </div>
              <div class="self-center text-[12px] font-medium w-[250px]">
                {{ fruit.name }}
              </div>
              <div class="self-center font-bold text-[#6C4BD8]">
                {{ number_format(fruit.selling_price, ' ', ' ') }} so'm
              </div>
            </div>
          </router-link>
        </div>
        <div class="item error text-black pl-[20px]" v-if="searcher && !products.length">
          <p class="pt-4">Hech narsa topilmadi!</p>
        </div>
      </div>
    </div>
  </div>
  <RegistrationDialog :show="registerDialog" @dialogAction="openRegisterDialog" />
  <Modal :show="dialogVisible" @hideModal="hideModal" />
  <Burger :show="offcanvas" @hideOffcanvas="hideOffcanvas" />
  <vue-basic-alert :duration="300" :closeIn="5000" ref="alert" />
</template>

<script>
import Modal from "@/components/layouts/Modal";
import Burger from "@/components/blocks/BurgerNav";
import i18n from "@/boot/i18n";
import axios from "axios";
import { apiUrls } from "@/utils/const/apiUrls";
import Karzinka from "@/components/blocks/Karzinka";
import RegistrationDialog from "../blocks/RegistrationDialog.vue";
import VueBasicAlert from 'vue-basic-alert'
import mixin from '@/boot/boot-mixin'
import { mapMutations, mapGetters } from "vuex";


export default {
  name: `Navbar`,
  mixins: [mixin],
  components: { Burger, Modal, Karzinka, RegistrationDialog, VueBasicAlert },
  data() {
    return {
      phoneNumber: '+998712625366',
      phoneNumberAdmin: '+998973431512',
      clientId: 'uzkarta_uz',
      clientSecret: '83nUdQc26UW4ZhIoiahG5QOE',
      state: 'eyJtZXRob2QiOiJJRFBXIn0=',
      searcherHover: false,
      inputFocus: false,
      oneIdUri: 'https://sso.egov.uz/sso/oauth/Authorization.do',
      code: window.location.search ? window.location.search.replace('?code=', '') : '',
      redirect_uri: location.origin.includes('localhost') ? 'http://localhost:8080/' : 'https://uzkarta.uz/',
      // redirect_uri: 'https://uzkarta.uz/',
      dialogVisible: false,
      registerDialog: false,
      offcanvas: false,
      currentlang: localStorage.getItem('lang'),
      products: [],
      filter: {
        name: ''
      },
      searcher: '',
      test: new Date().getTime()
    }
  },
  computed: {
    oneIDUri() {
      return this.oneIdUri + '?response_type=one_code&client_id=' + this.clientId
        + '&redirect_uri=' + this.redirect_uri + '&scope=' + this.clientId + '&state=' + this.state
    },
    storeUser() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    ...mapMutations([
      'setLang'
    ]),

    ...mapGetters([
      'getLang'
    ]),
    makeCall() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.href = "tel:" + this.phoneNumber;
      } else {
        this.showWarningInfo(this.$t('system.navbar.callsNotSupport'));
      }
    },
    makeCallAdmin() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.href = "tel:" + this.phoneNumberAdmin;
      } else {
        this.showWarningInfo(this.$t('system.navbar.callsNotSupport'));
      }
    },
    setLan(val) {
      this.setLang(val);
      i18n.global.locale = val
      // localStorage.setItem('lang', val);
      window.location.reload()
    },

    reloadStoreItem() {
      this.test = new Date().getTime()
    },

    checkOrder(data) {
      if (data.check) this.showInfo(data.message)
      else this.showErrorInfo(data.message)
    },

    toCabinet() {
      let href = window.location.href;
      href = href.split('?code=');
      window.location.href = href[0] + '#cabinet';
    },
    openRegisterDialog() {
      this.registerDialog = !this.registerDialog;
      // console.log(this.registerDialog);
    },
    searchProduct() {
      this.$emit('searchProduct');
    },
    hideModal() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    hideOffcanvas() {
      this.offcanvas = false;
    },
    simulation() {
      let element = document.querySelector('#lang-menu');
      element.dispatchEvent(new KeyboardEvent('keydown', {
        key: "Escape",
        keyCode: 27,
        which: 27,
        code: "KeyEscape",
        location: 0,
        altKey: false,
        ctrlKey: false,
        metaKey: false,
        shiftKey: false,
        repeat: false
      }));
    },
    getToken() {
      axios.post(this.oneIdUri + '?grant_type=one_authorization_code&' +
        'client_id=' + this.clientId + '&client_secret=' + this.clientSecret + '&code=' + this.code +
        '&redirect_uri=' + this.redirect_uri)
        .then(res => {
          this.getData(res.data.access_token)
        })
    },
    getData(token) {
      axios.post(this.oneIdUri + '?grant_type=one_access_token_identify&' +
        'client_id=' + this.clientId + '&client_secret=' + this.clientSecret +
        '&access_token=' + token + '&scope=' + this.code)
        .then(res => {
          this.oauth(res.data)
        })
    },
    oauth(data) {
      axios.post(apiUrls.ONE_ID_OAUTH2, data).then(res => {
        this.$store.dispatch("login", res.data).then(() => {
          this.toCabinet()
        }
        )

      })
    },
    init(val) {
      axios.get(apiUrls.PRODUCTS + '/with-images' + "?" + this.setParams({ name: val }))
        .then(res => {
          this.products = res.data.data;
        })
    },
    resetInput() {
      this.inputFocus = false;
      this.searcher = '';
    },
  },
  watch: {
    searcher: function (val) {
      this.init(val)
      this.inputFocus = val.length > 0;
    }
  },
  mounted() {
    let user = this.storeUser
    if (this.code && !user.id) {
      this.getToken()
    }
    i18n.global.locale = this.getLang();
  }
}
</script>

<style scoped>
.overflow {
  overflow-y: scroll;
  max-height: calc(100vh - 300px);
  border: 1px solid #8764F8;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #8764F8;
  border: none;
  width: 10px;
}

.input:focus {
  border: none !important;
  outline: none !important;
}

.burger-line {
  width: 30px;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
}

.vertical-divider-1 {
  width: 1px;
  height: 25px;
  background-color: white;
  border-radius: 999999px;
  margin-left: 17.3px;
  margin-right: 14.7px;
}

.vertical-divider-2 {
  width: 1px;
  height: 25px;
  background-color: white;
  border-radius: 999999px;
  margin-left: 16.7px;
  margin-right: 15.3px;
}

/* .uk-active { */
/* color: #000 !important; */
/* } */
</style>