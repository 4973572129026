export default {
    "system": {
        "actions": "Harakatlar",
        "activesessiondate": "Aktiv sessiya sanasi",
        "app_action_group_name": "Ilova harakatlar guruhi nomi",
        "are_you_sure_logout": "Tizimdan chiqmoqchimisiz?",
        "cancel": "Bekor qilish",
        "created_date": "Yaratilgan sana",
        "created_user_name": "Yaratgan foydalanuvchi nomi",
        "data_not_found": "Ma`lumotlar topilmadi",
        "description": "Tavsif",
        "edit": "Tahrirlash",
        "email": "Elektron pochta",
        "error_connection": "Serverga ulanish xatosi",
        "female": "Ayol",
        "fio": "FISH",
        "gender": "Jinsi",
        "id": "ID",
        "is_module": "Modul",
        "enter": "KIRISH",
        "text": "Tizimda ishlashni davom ettirish uchun, login va parol kiring",
        "login": "Login",
        "password": "Parol",
        "remember": "Eslab qolish",
        "forgot_password": "Parolni tiklash",
        "reset": "Qayta tiklash",
        "logout": "Chiqish",
        "male": "Erkak",
        "name": "Nomi",
        "new": "Yangi yaratish",
        "no": "Yo'q",
        "organization_name": "Tashkilot nomi",
        "os_info": "OT",
        "phone": "Telefon",
        "remote_address": "Masofaviy manzil",
        "role": "Foydalanuvchi ro'li",
        "save": "Saqlash",
        "short_name": "Qisqa nomi",
        "status": "Holati",
        "to_back": "Orqaga",
        "token": "Token",
        "user_name": "Foydalanuvchi FIO",
        "user_role_name": "Foydalanuvchi roli",
        "value": "Qiymat",
        "yes": "Ha",
        "delete": "O'chirish",
        "iichdk": "IICHDK",
        "cartography": "KARTOGRAFIYA",
        "maps_and_atlas": "Xarita va Atlaslar",
        "pay_out": "To'lash",
        "social_media_text": "Biz ijtimoiy tarmoqlardamiz",
        "client_confirm": "Tasdiqlandi",
        "client_paid": "To'landi",
        "invoice_number": "Invois raqami",
        "all_amount": "Jami narxi",
        "all_count": "Jami soni",
        "sent_time": "Yuborilgan sana",
        "products_name": "Mahsulot nomi",
        "count": "Soni",
        "price": "Narxi",
        "product_info": "Mahsulot haqida ma'lumot",
        "product_img": "Mahsulot rasmi",
        "full_info": "To'liq malumot",
        "are_you_sure_remove_this_row": "Chindan ham ushbu qatorni o'chirmoqchimisiz?",
        "required": "Majburiy maydon",
        "are_you_sure_set_status_this_row": "Chindan ham ushbu qatorni holatini o'zgartirmoqchimisiz?",
        "qollab_quvvatlash": "Qo'llab-quvvatlash xizmati",
        "call_admin": "Admin bilan bog'lanish",
        "yetkazib_berish": "Yetkazib berish",
        "savat": "Savatcha",
        "kabinet": "Kabinet",
        "category": "Kategoriyalar",
        "xarid_qilishga_shoshiling": "Xarid qilishga shoshiling",
        "milliy_atlas": "O'zbekiston milliy atlasi",
        "atlas_yaratildi": "O'zbekiston milliy atlasi yaratildi",
        "batafsil": "Batafsil",
        "yangi_mahsulot": "Yangi mahsulotlar",
        "add_bucket": "Savatchaga qo'shish",
        "deliver": "Yetkazib berish",
        "mahsulot": "Mahsulotlar",
        "bozorga_borishni_hojati_yoq": "Endi bozorga borishingizni hojati yoq",
        "qulay_narxda_yetkazib": "Bizda qulay narxlar va uygacha yetkazib berish xizmati",
        "tez_yetkazish": "O'zbekiston bo'ylab tez yetkazib berish xizmati",
        "qoyil_qolasiz": "Bizning xizmatlardan qoyil qolasiz",
        "location": "Toshkent, 100170, M.Ulug`bek tumani, Ziyolilar ko`cha ,6",
        "inform": "Ma'lumotlar",
        "deliver_inform": "Yetkazib berish to'g'risida ma'lumotlar",
        "back_product": "Mahsulotni qaytarish",
        "about": "Biz haqimizda",
        "messenger": "Bizni ijtimoiy tarmoqlarda kuzating",
        "address_in_map": "Bizning manzilimiz xaritada",
        "back_footer": " O'zbekiston Respublikasi Iqtisodiyot va moliya vazirligi huzuridagi Kadastr agentligi. Barcha huquqlar himoyalangan.",
        "tizim": "Tizim",
        "tizim_a": "davlat muassasi tomonidan ishlab chiqilgan",
        "choose_payment": "To'lov shaklini tanlang",
        "through_pay": "Orqali to'lash",
        "naqd_tola": "Naqd shaklida",
        "trans_shakli": "Pul o'tkazish shaklida",
        "your_order": "Sizning buyurtmangiz",
        "result": "Natija",
        "all_of_them": "Jami",
        "pay_through_payme": "Payme orqali toʻlash",
        "pay_through_click": "Click orqali toʻlash",
        "bosh_sahifa": "Bosh sahifa",
        "buyurtma_berish": "Buyurtma berish",
        "buyurtmani_shakllantirish": "Buyurtmani shakillantirish",
        "your_inform": "Sizning ma'lumotlaringiz",
        "ism": "Ism",
        "surname": "Familiya",
        "location_a": "Joylashuv",
        "viloyat": "Viloyat",
        "tuman": "Shahar / Tuman",
        "region": "Mintaqa / hudud",
        "manzil": "Manzil",
        "see_all": "Yana ko'rish",
        "placeholder_1": "Misol: Chilonzor tumani 20kv",
        "som": "so'm",
        "personal_cabinet": "Shaxsiy kabinetga kirish",
        "parolni_unutdingizmi": "Parolni unutdingizmi?",
        "hisobingiz_yoqmi": "Hisobingiz yo'qmi?",
        "register": "Roʻyxatdan oʻtish",
        "maydon_notogri": "Maydon noto'g'ri to'ldirilgan",
        "tasdiqlash": "Tasdiqlash",
        "yetkazib_beriladi": "O'zbekiston Respublikasi bo'ylab yetkazib berish",
        "sevimli": "Sevimlilar",
        "toza": "Toza",
        "yaqinda_korilgan_mahsulot": "Yaqinda ko'rilgan mahsulotlar",
        "buyurtma_asosida_xarid_qiling": "Sotuvga chiqarilgan kartografik maxsulotlarni buyurtma asosida xarid qilib oling.",
        "alohida_hisoblanadi": "Òzbekiston bòyicha yetkazib berish xizmati alohida hisoblanadi",
        "online_shop": "onlayn do'kon",
        "eslab_qolish": "meni eslab qolish",
        "korib_chiqishingizni_maslahat": "Ko'rib chiqishingizni maslahat beramiz",
        "oquv_atlaslari": "O'quv atlaslari",
        "olkashunoslik": "O'zbek va rus tillarida chop etilgan o'lkashunoslik atlaslari",
        "yozuvsiz_xaritalar": "Geografiya fani uchun mo'ljallangan o'quv atlaslari va yozuvsiz xaritalar",
        "uz_sum": "so'm",
        "all_category": "Barcha bo'limlar",
        "track_status": "Yetkazib berish holati",
        "copied_error": "Xato",
        "copied": "Muvaffaqiyatli",
        "copied_error_message": "Havoladan nusxa olishda xatolik yuz berdi",
        "copied_message": "Joriy havolaning nusxasi bufferga saqlandi",
        "ish_vaqti": "Ish vaqti",
        "site_is_test_mode": "Sayt test rejimida ishlamoqda",
        "search": "Qidiruv",
        "dona": "dona",
        "shaxsiy_kabinet": "Shaxsiy kabinet",
        "shaxsiy_malumotlar": " Shaxsiy ma`lumotlar",
        "mening_buyurtmalarim": "Mening buyurtmalarim",
        "buyurtma_raqami": "Buyurtma raqami",
        "buyurtma_sanasi": "Buyurtma sanasi",
        "yuboruvchida": "Yuboruvchida",
        "kuryer_qabul_qildi": "Kuryer qabul qildi",
        "kuryer_yetkazib_beradi": "Kuryer yetkazib beradi",
        "yetkazib_berildi": "Yetkazib berildi",
        "bekor_qilmoq": "Siz haqiqatdan ham ushbu buyurtmani bekor qilmoqchimisiz?",
        "tolov": "To'lov",
        "chegirma": "chegirma",
        "history_purchase": "Sotib olish tarixi",
        "sevimlilar_royxati": "Sevimlilar ro'yxati bo'sh",
        "sevimliga_qoshish": "Sevimli mahsulotlaringizni keyinroq ko'rish yoki sotib olish uchun ularni sevimlilaringizga qo'shing",
        "ulashing": "Ulashing",
        "sotuvda": "Sotuvda",
        "mavjud": "Mavjud",
        "savatchaga": "Savatchaga",
        "previous": "Oldingi",
        "next": "Keyingi",
        "oneid": "OneId orqali",
        "sms": "SMS habarnoma orqali",
        "enter_to_system": "Shaxsiy kabinetga kirish",
        "codeLogin": "Faollashtirish kodini oling",
        "code": "Kod",
        "cabinetSection": {
            "leaveAcc": "Chiqmoqchimisiz?"
        },
        "karzinka": {
            "totalPrice": "Jami"
        },
        "navbar": {
            "callsNotSupport": "Bu qurilmada telefon qo‘ng‘iroqlari qo‘llab-quvvatlanmaydi."
        },
        "OrderKarzinka": {
            "shippingPrice" : "Yetkazib berish narxi"
        }
    }
};
