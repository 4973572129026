<template>
    <div>
        <Transition name="slide-top">
            <div class="fixed w-full h-[325px] bg-[#6848D2] z-[9999999]
                 right-0 top-0 left-0"
                 v-if="show"
            >
                <div @click="this.$emit('hideOffcanvas')" class="uk-flex uk-flex-between items-top p-[20px]">
                    <div>
                        <router-link to="/" class="td ">
                            <div class="uppercase navbar-item">
                                Kartografiya
                            </div>
                            <div class="navbar-item uppercase font-bold">
                                IICHDK
                            </div>
                        </router-link>
                    </div>
                    <div>
                        <img src="@/assets/close-icon.svg" alt="close" class="cursor-pointer hover:scale-150">
                    </div>
                </div>
                <div class="px-[20px] flex flex-col">
                    <div class="flex flex-col items-center">
                        <div class="flex flex-row w-full md:ml-auto mt-[20px]">
                            <div class="basis-1/3">
                                <a href="javascript:void(0);" class="flex flex-col td justify-center ">
                                    <div class="mx-auto">
                                        <img src="@/assets/dastavka-icon.svg" alt="">
                                    </div>
                                    <div class="mt-[8px] mx-auto text-[14px] sm:text-[16px] text-white whitespace-nowrap">
                                        {{$t('system.deliver')}}
                                    </div>
                                </a>
                            </div>
                            <div class="vertical-divider-1"></div>
                            <div class="basis-1/3">
                                <a href="javascript:void(0);" class="flex flex-col td justify-center  relative"
                                   uk-toggle="target: #offcanvas-flip">
                                    <div class="mx-auto">
                                        <img src="@/assets/korzinka-icon.svg" alt="">
                                    </div>
                                    <div class="text-[14px] sm:text-[16px] text-white mt-[7px] mx-auto">
                                        {{$t('system.savat')}}
                                    </div>
                                    <div
                                        class="absolute top-[-10px] right-[9px] text-[#fff] text-[15px] bg-[#14BF30] px-[7px]  rounded-[50%]">
                                        2
                                    </div>
                                </a>
                            </div>
                            <div class="vertical-divider-2"></div>
                            <div class="basis-1/3">
                                <a href="javascript:void(0);" class="flex flex-col td justify-center cabinet"
                                   @click="showDialog">
                                    <div class="mx-auto">
                                        <img src="@/assets/kabinet-icon.svg" alt="icon">
                                    </div>
                                    <div class="text-[14px] sm:text-[16px] text-white mt-[4.4px] mx-auto">
                                        {{$t('system.kabinet')}}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="mt-[20px]">
                        <div class="uk-position-relative flex flex-row justify-center">
                            <form action="" class="relative inline">
                                <input type="text" placeholder="Qidiruv"
                                       class="px-[23px] input py-[13px] bg-[#fff] md:w-[615px] sm:w-[415px]
                                       sm:h-[46px] h-[36px] focus:outline-0 rounded-[5px] shadow-sm w-[270px]">
                                <a href="#">
                                    <img src="@/assets/search-icon.svg" alt="search"
                                         class="absolute right-[25px] bottom-[10px]">
                                </a>
                            </form>
                            <div class="flex  items-center cursor-pointer md:ml-auto ml-[5px]"
                                 uk-filter="target: .js-filter; animation: fade"
                            >
                                <div class="flex flex-row items-center">
                                    <div class="navbar-item js-filter flex flex-row">
                                        <div class="tag-ru text-[16px] font-medium">
                                            Ру
                                        </div>
                                        <div class="tag-uz text-[16px] font-medium">
                                            O'z
                                        </div>
                                    </div>
                                    <div class="sm:ml-[12px] ml-2 pt-[5px]">
                                        <img src="@/assets/dropdown-icon.svg" alt="dropdown">
                                    </div>
                                </div>
                                <div  uk-dropdown="mode: click;pos: bottom-right;"
                                      id="lang-menu"
                                      @click="simulation"
                                      class="rounded-[5px] bg-[#fff] uk-box-shadow-medium"
                                      style="padding: 10px!important; min-width: 47px!important;">
                                    <div class="uk-active text-[16px] font-medium text-[#6C4BD8] hover:text-[#000] border-b pb-2"
                                         uk-filter-control=".tag-ru"
                                    >
                                    Русский
                                    </div>
                                    <div class="text-[#6C4BD8] hover:text-[#000] text-[16px] font-medium pt-2"
                                         uk-filter-control=".tag-uz"
                                    >
                                    O'zbek
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-[20px] flex">
                        <div class="flex md:flex-row flex-col md:ml-auto mx-auto">
                            <div class="flex flex-row items-center md:border-r-2 pr-[12px] justify-center">                             
                            </div>
                            <div class="flex sm:flex-row flex-col items-center md:ml-[12px] justify-center pr-[12px]">
                                <div class="text-white text-[14px] sm:text-[16px]">
                                    {{ $t('system.qollab_quvvatlash') }}
                                </div>
                                <div class="ml-[15px]"> 
                                    <a class="font-medium text-white text-[14px] sm:text-[16px]" href="tel:+998712625366">71 262 - 53 - 66</a>
                                </div>
                            </div>
                            <div class="flex flex-row items-center md:border-r-2 pr-[12px] justify-center">
                            </div>
                            <div class="flex sm:flex-row flex-col items-center md:ml-[12px] justify-center pr-[12px]">
                                <div class="text-white text-[14px] sm:text-[16px]">
                                    {{ $t('system.call_admin') }}
                                </div>
                                <div class="ml-[15px]">
                                    <a class="font-medium text-white text-[14px] sm:text-[16px]" href="tel:+998973431512">97 343-15-12</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    name: `Burger`,
    props: {
        show: Boolean
    },
}
</script>
<style>
.slide-top-enter-active {
    transition: all 0.3s ease-out;
}

.slide-top-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-top-enter-from,
.slide-top-leave-to {
    transform: translateY(-200px);
    opacity: 0;
}
.vertical-divider-1 {
    width: 1px;
    height: 55px;
    background-color: white;
    border-radius: 999999px;
    margin-left: 14px;
    margin-right: 14px;
}

.vertical-divider-2 {
    width: 1px;
    height: 55px;
    background-color: white;
    border-radius: 999999px;
    margin-left: 14px;
    margin-right: 14px;
}
</style>