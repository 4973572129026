<template>
    <div class="mb-[60.8px]">
        <HeaderCheck class="header-underline-payment mb-[48.7px]">
            <template #name>
                {{ $t('system.choose_payment') }}
            </template>
        </HeaderCheck>
        <div class="flex flex-col">
            <div class="uk-flex">
                <InputPayment class="">
                    <div class="text-[#000]  font-medium text-[16px]">
                        {{ $t('system.through_pay') }}
                    </div>
                    <div>
                        <img class="" src="@/assets/click.svg" alt="">
                    </div>
                </InputPayment>
                <InputPayment class="ml-[21px]">
                    <div class="text-[#000] font-medium text-[16px]">
                        {{ $t('system.through_pay') }}
                    </div>
                    <div>
                        <img src="@/assets/payme_01.svg" alt="">
                    </div>
                </InputPayment>
            </div>

            <div class="uk-flex mt-[21.3px]">
                <InputPayment class="disable_link">
                    <div class="dis_color font-medium text-[16px]">
                        {{ $t('system.naqd_tola') }}
                    </div>
                    <div class="uk-flex">
                        <img class="dis_img" src="@/assets/money.svg" alt="">
                        <img src="@/assets/uzcard1.svg" alt="" class="dis_img ml-[12px] mr-[8px]">
                        <img class="dis_img" src="@/assets/system_humo_c.svg" alt="">
                    </div>
                </InputPayment>

                <InputPayment class="disable_link ml-[21px]">
                    <div class="dis_color font-medium text-[16px]">
                        {{ $t('system.trans_shakli') }}
                    </div>
                    <div>
                        <img class="dis_img" src="@/assets/building-icon.svg" alt="">
                    </div>
                </InputPayment>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderCheck from "@/components/blocks/HeaderCheck";
    import InputPayment from "@/components/blocks/InputPayment";

    export default {
        name: "PaymentType",
        components: {InputPayment, HeaderCheck}
    }
</script>

<style scoped>
    .disable_link {
        pointer-events: none;
        cursor: default;
    }
    .dis_img {
        opacity: 0.5;
    }

    .dis_color {
        color: #c3c3c3;
    }
</style>